

.container{
    
    background: "transparent";
    display: flex;
    align-items: center;
    justify-content: center;
}

.letterContainer{
    width: 35px;
    height: 35px;
    border: 3px solid #8CBED6;
    
    display: flex;
    align-items: center;
    margin: 2px;
    justify-content: center;
    position: relative;
}

.endLetterContainer{
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.startLetterContainer{
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.logoLetter{
    font-size: 30px;
    color: #CEC6C6;
    font-style: italic;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}





